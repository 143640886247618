<template>
  <div class="content tab-page">
    <div class="header">
      <div class="wallet-panel">
        <div class="connect-wallet-notice">
          <div class="avatar-and-hash">
            {{ connect }}
            <!-- <img class="avatar" :src="walletInfo.icon" alt="" v-if="walletInfo.icon"> -->
            <img class="avatar" src="@/static/img/home/avatar.png" alt="" />
            <div class="wallet-hash">{{ walletInfo.address }}</div>
          </div>
          <span @click="changeLang" v-if="mbLang === 'ZH'"
            >English<img src="../../static/img/home/changLan.png" alt=""
          /></span>
          <span @click="changeLang" v-else
            >繁體中文<img src="../../static/img/home/changLan.png" alt=""
          /></span>
        </div>
        <div class="wallet-info">
          <!-- nft骑士卡 -->
          <div class="nft-wrapper" @click="handleToKnight">
            <div class="nft-tag inactive" v-if="!myWallet.nftLevel">
              {{ knightLevel.leval_0 }}
            </div>
            <div class="nft-tag" :class="`nft-bg-${myWallet.nftLevel}`" v-else>
              <img
                class="nft-icon"
                :src="
                  require(`@/static/img/knights/level_${myWallet.nftLevel}.png`)
                "
              />
              <span>{{ knightLevel[`leval_${myWallet.nftLevel}`] }}</span>
            </div>
            <div class="line-jump" v-if="!myWallet.nftLevel">
              {{
                myWallet.nftLevel ? knightLevel.detail : knightLevel.toActive
              }}
            </div>
          </div>
          <div class="rank-and-btn">
            <!-- 隐藏节点 +++ 等级 start -->
            <div class="point-wrap" v-if="false">
              <div class="point-wrapper" v-if="myWallet.nodeLevel">
                <div class="item" v-if="myWallet.nodeLevel === 2">
                  <SvgIcon iconClass="bigPoint" class="point-icon"></SvgIcon>
                </div>
                <div class="item" v-else>
                  <SvgIcon iconClass="smallPoint" class="point-icon"></SvgIcon>
                </div>
              </div>
              <div
                class="rank"
                style="color: #b28022"
                v-if="myWallet.level === 1"
              >
                {{ level.bronze }}Ⅰ
              </div>
              <div
                class="rank"
                style="color: #b28022"
                v-if="myWallet.level === 2"
              >
                {{ level.bronze }}Ⅱ
              </div>
              <div
                class="rank"
                style="color: #b28022"
                v-if="myWallet.level === 3"
              >
                {{ level.bronze }}Ⅲ
              </div>
              <div
                class="rank"
                style="color: #d8d8d8"
                v-if="myWallet.level === 4"
              >
                {{ level.silver }}Ⅰ
              </div>
              <div
                class="rank"
                style="color: #d8d8d8"
                v-if="myWallet.level === 5"
              >
                {{ level.silver }}Ⅱ
              </div>
              <div
                class="rank"
                style="color: #d8d8d8"
                v-if="myWallet.level === 6"
              >
                {{ level.silver }}Ⅲ
              </div>
              <div
                class="rank"
                style="color: #eab85b"
                v-if="myWallet.level === 7"
              >
                {{ level.gold }}Ⅰ
              </div>
              <div
                class="rank"
                style="color: #eab85b"
                v-if="myWallet.level === 8"
              >
                {{ level.gold }}Ⅱ
              </div>
              <div
                class="rank"
                style="color: #eab85b"
                v-if="myWallet.level === 9"
              >
                {{ level.gold }}Ⅲ
              </div>
            </div>
            <!-- 隐藏节点 +++ 等级 end -->
            <div class="change-wallet" @click="changeWallet">{{ change }}</div>
          </div>
        </div>
      </div>
      <!-- 隐藏原力值 + 助力值 -->
      <div class="data-group" v-if="false">
        <div class="data">
          <div class="img">
            <img src="@/static/img/home/mineral_green.gif" alt="" />
          </div>
          <div class="data-name" :class="{ w75: mbLang === 'EN' }">
            {{ forceValue }} :
          </div>
          <div class="progress">
            <!-- <van-progress :percentage="forceValuePercent" stroke-width="5" :show-pivot="false" /> -->
            <div
              class="progress-percent"
              style="background-color: #25e4b2; height: 5px; border-radius: 3px"
              :style="{ width: forceValuePercent + '%' }"
            ></div>
            <div class="data-num">
              {{ $gbUtils.formatValue(myWallet.forceValue) }}
            </div>
          </div>
        </div>
        <div class="data">
          <div class="img">
            <img src="@/static/img/home/mineral_puple.gif" alt="" />
          </div>
          <div class="data-name" :class="{ w75: mbLang === 'EN' }">
            {{ boostValue }} :
          </div>
          <div class="progress">
            <div
              class="progress-percent"
              style="background-color: #9382ef; height: 5px; border-radius: 3px"
              :style="{ width: helpValuePercent + '%' }"
            ></div>
            <div class="data-num">
              {{ $gbUtils.formatValue(myWallet.helpValue) }}
            </div>
          </div>
        </div>
      </div>
      <!-- 隐藏原力值 + 助力值 -->
    </div>
    <HomeNotice
      ref="homeNotice"
      :announcement="announcement"
      v-if="announcement && false"
    ></HomeNotice>
    <div class="padding-layout">
      <div class="coin-group">
        <div
          class="change-all"
          @click="handleGetAllMineral"
          v-show="mineralList.length !== 0"
        >
          <img src="../../static/img/home/getAll.png" alt="" />{{ getAll }}
        </div>
        <div id="random-panel" class="random-panel">
          <template v-if="mineralList.length !== 0">
            <div
              class="random-item"
              :ref="'item' + index"
              v-for="(item, index) in mineralList"
              :key="index"
              :style="{ left: item.x / 2 + 'px', top: item.y / 2 + 'px' }"
              @click="handleMineral(item, index)"
            >
              <img
                src="@/static/img/home/mineral_green.gif"
                class="random-coin"
                v-if="item.goldType === 1"
              />
              <img
                src="@/static/img/home/mineral_puple.gif"
                class="random-coin"
                v-else
              />
              <span class="random-num">{{ item.amount }}</span>
            </div>
          </template>
          <template
            v-else-if="
              mineralList.length === 0 &&
              myWallet.forceValue === 0 &&
              myWallet.helpValue === 0
            "
          >
            <img
              src="../../static/img/home/none-static.png"
              alt=""
              class="none"
            />
          </template>
          <template v-else>
            <img
              src="../../static/img/home/none-doing.gif"
              alt=""
              class="none"
            />
          </template>
        </div>
        <div class="button-group">
          <div
            class="warehouse-btn"
            @click="$gbUtils.handleToPage(`/warehouse?title=${home.warehouse}`)"
          >
            {{ home.warehouse }}
          </div>
          <div class="GEP-notice" v-if="false">{{ gepNotice }}</div>
        </div>
      </div>
    </div>
    <NoticePopup v-if="noticePop" @close="closeNotice" :dataSource="noticeDtat"/>
    <!-- <button @click="send">发币</button> -->
    <!-- <button @click="sendForceValue">发送原力值</button> -->
    <!-- <BottomTabBar :selected="0" /> -->
    <!-- <Login v-if="loginPopShow" @hidePop="hidePop" @loginSuccess="loginSuccess" /> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SvgIcon from '@/components/SvgIcon.vue'
import HomeNotice from './components/HomeNotice.vue'
import NoticePopup from '../notice/components/NoticePopup'
// import MbButton from '@/components/mb-button/MbButtom.vue'
import {
  queryBannerList,
  queryMineral,
  queryGetMineral,
  sendCoin,
  sendForceValue,
  queryGetAllMineral
} from '@/services/home'
import { queryNoticeList } from '@/services/notice'
import { queryWalletInfo, queryMyWallet } from '@/services/wallet'
// import Login from '../../components/common/Login.vue'
export default {
  components: {
    SvgIcon,
    HomeNotice,
    NoticePopup
  },
  name: 'Home',
  data () {
    return {
      announcement: {},
      loginPopShow: false,
      title: 'Hello, Meblox',
      value: 50,
      coinPosition: [],
      totalMineralList: [],
      mineralList: [],
      allMineralList: [],
      mineralNumber: 0,
      mineralSize: 10,
      mineralNo: 1,
      bannerList: [],
      forceValuePercent: 0,
      helpValuePercent: 0,
      walletInfo: {},
      myWallet: {},
      noticePop: false,
      noticeDtat: {
        englishTitle: 'Announcement on the closure of the single-currency pledge function',
        zhTitle: '關於單幣質押功能關閉的相關通知',
        createTime: '2022-7-21',
        englishText: '<div style="text-indent:2em;">Hello dear Meblox users! Thank you for your continued support!</div>' +
                '<div style="text-indent:2em;">Meblox team will closed the single-currency pledge function at 4:00 (GMT+8) on July 22, 2022. The Xmeb corresponding to the user`s pledge amount has been airdropped to the pledged users. Redemption related operations will be carried out before 4:00 (GMT+8)July 31, 2022.</div>' +
                '<br/>Thank you for your support!',
        zhText: '<div style="text-indent:2em;">尊敬的Meblox用戶您好！感謝您一直以來的支持！</div>' +
        '<div style="text-indent:2em;">Meblox團隊将在2022年7月22號 4:00（GMT+8）關閉單幣質押功能，現已按照用戶質押數量空投對應比例的Xmeb給質押用戶，質押用戶需在2022年7月31號 4:00（GMT+8）前進行贖回相關操作。</div>' +
        '<br/>感謝您的支持！'
      }
    }
  },
  methods: {
    ...mapActions(['changeMbLang', 'changeToken', 'changeAccount']),
    closeNotice () {
      this.noticePop = false
    },
    // 切换钱包
    async changeWallet () {
      // const resp = await this.$web3.disconnectWallet()
      // console.log(resp)
      this.changeToken('')
      localStorage.removeItem('authorized')
      this.changeAccount('')
      localStorage.removeItem('mbMyAcount')
      this.$parent.judgeLogin()
    },

    // 发原力值
    async sendForceValue () {
      const resp = await sendForceValue({
        account: 'lycs',
        password: 'lycs',
        amount: 10
      })
      console.log(resp)
    },
    // 分发硬币
    async send () {
      const resp = await sendCoin({
        account: 'lycs',
        password: 'lycs'
      })
      console.log(resp)
    },
    changeLang () {
      const lang = this.mbLang === 'ZH' ? 'EN' : 'ZH'
      this.changeMbLang(lang)
      // this.$i18n.locale = lang
      // this.setTabBar()
    },
    // 获取矿石
    async getMineral () {
      this.mineralList = []
      const resp = await queryMineral()
      if (!resp.success) return
      this.mineralList = resp.result.records
      this.mineralNumber = this.mineralList.length
      if (this.mineralNumber > 0) {
        this.createCircle()
      }
    },
    // 点击矿石
    async handleMineral (item, index) {
      this.$refs['item' + index][0].style.pointerEvents = 'none'
      const resp = await queryGetMineral({
        goldId: item.id,
        operation: 1,
        number: item.amount
      })
      this.$emit('changeLoading', false)
      if (!resp.success) return
      // this.$toast(this.mbLang === 'ZH' ? '收取成功' : 'Success')
      this.mineralDisappearance(index)
      this.mineralNumber--
      // console.log(this.mineralList)
      if (this.mineralNumber === 0) {
        const resp1 = await queryMineral()
        if (!resp.success) return
        this.mineralList = resp1.result.records
        this.mineralNumber = this.mineralList.length
        for (const i in this.mineralList) {
          this.mineralList[i].x = this.coinPosition[i].x
          this.mineralList[i].y = this.coinPosition[i].y
          // this.$refs['item' + index][0].style.pointerEvents = ' '
          this.$refs['item' + i][0].style.opacity = 1
          this.$refs['item' + i][0].style.pointerEvents = 'auto'
        }
      }
    },
    // 矿石消失
    mineralDisappearance (index) {
      this.$refs['item' + index][0].style.opacity = 0
      this.$refs['item' + index][0].style.top =
        parseInt(this.$refs['item' + index][0].style.top) + 10 + 'px'
      // this.$refs['item' + index][0].style.pointerEvents = 'none'
    },
    // 一键收取矿石
    async handleGetAllMineral () {
      if (this.mineralList.length === 0) {
        return
      }
      const resp = await queryGetAllMineral()
      if (!resp.success) return
      this.mineralList = []
      // this.getMineral()
    },
    async changeAll () {
      this.$toast.loading({ duration: 0 })
      const resp = await queryMineral({
        pageSize: 1000,
        pageNumber: 1
      })
      const allMineral = resp.result.records
      console.log(allMineral)
      // for (let i = 0; i < resp.total; i++)  {
      //   const resp1 = await
      // }
      allMineral.forEach(async (item, index) => {
        const resp = await queryGetMineral({
          goldId: item.id,
          operation: 1,
          number: item.amount
        })
        if (!resp.success) return this.$toast.clear()
      })
      this.$toast.clear()
      this.$toast(this.mbLang === 'ZH' ? '收取成功' : 'Success')
      this.mineralList = []
    },
    // 获取我的钱包
    async getMyWallet () {
      const resp = await queryMyWallet()
      if (!resp.success) return
      this.myWallet = resp.result
      this.myWallet.forceValue = this.$gbUtils.formatBalance(
        this.myWallet.forceValue
      )
      this.myWallet.helpValue = this.$gbUtils.formatBalance(
        this.myWallet.helpValue
      )
      // console.log(this.myWallet)
      if (this.myWallet.forceValue > this.myWallet.helpValue) {
        this.forceValuePercent = 100
        this.helpValuePercent =
          (this.myWallet.helpValue / this.myWallet.forceValue) * 100
      } else if (this.myWallet.forceValue < this.myWallet.helpValue) {
        this.helpValuePercent = 100
        this.forceValuePercent =
          (this.myWallet.forceValue / this.myWallet.helpValue) * 100
      } else {
        this.forceValuePercent = 100
        this.helpValuePercent = 100
      }
      // if (resp.forceValue !== 0) {
      //   const resp1 = await queryBindingWallet()
      //   if (!resp1.success) return
      //   console.log(resp)
      // }
    },
    // 获取钱包信息
    async getWalletInfo () {
      const resp = await queryWalletInfo()
      if (!resp.success) return
      this.walletInfo = resp.result
      // 判断钱包是否一致
      // toUpperCase()：将字符串str中的字符都转换为大写。
      // toLowerCase()：将字符串str中的字符都转换为小写。
      const nowAmount = resp.result.address.toUpperCase()
      const myAcount = this.myAcount.toUpperCase()
      if (myAcount !== nowAmount) {
        this.changeToken()
        this.changeAccount()
      }
      const startAddress = resp.result.address.slice(0, 4)
      const endAddress = resp.result.address.slice(-4)
      this.walletInfo.address = startAddress + '...' + endAddress
      // console.log(this.walletInfo)
    },
    // 获取区间的值
    randomFrom (lowerValue, upperValue) {
      return parseInt(
        Math.floor(Math.random() * (upperValue - lowerValue + 1) + lowerValue)
      )
    },
    // 获取圆心
    getRandomPoint (
      i,
      radius = 50,
      minWidth = 50,
      // maxWidth = window.screen.width * 1.6,
      maxWidth = document.getElementById('random-panel').clientWidth * 1.8,
      minHeight = 50,
      maxHeight = 500
    ) {
      // const x = this.randomFrom(350 + 50, 350 - 50) - 60
      // const y = parseInt(Math.random() * 450) - 60
      // return { x, y }
      let x, y
      switch (i) {
        case 0:
          x = this.randomFrom(maxWidth / 2 + 50, maxWidth / 2 - 50) - radius
          y = this.randomFrom(maxHeight / 2 + 50, maxHeight / 2 - 50) - radius
          return { x, y }
        default:
          x = this.randomFrom(minWidth, maxWidth) - radius
          y = this.randomFrom(minHeight, maxHeight) - radius
          return { x, y }
      }
    },
    // 判断是否重叠
    testOverlay (pointA, pointB, radius) {
      const x = Math.abs(pointA.x - pointB.x)
      const y = Math.abs(pointA.y - pointB.y)
      const distance = Math.sqrt(x * x + y * y)
      if (distance >= radius * 2) {
        return false
      } else {
        return true
      }
    },
    // 判断是否可获取
    testAvailable (pointArr, newPoint, radius) {
      const arr = [...pointArr]
      let aval = true
      while (arr.length > 0) {
        const lastPoint = arr.pop()
        if (this.testOverlay(lastPoint, newPoint, radius)) {
          aval = false
        }
      }
      return aval
    },
    // 创建圆
    createCircle () {
      for (let i = 0; i < this.mineralNumber; i++) {
        const newPoint = this.getRandomPoint(i)
        if (this.testAvailable(this.coinPosition, newPoint, 60)) {
          if (this.coinPosition.length !== this.mineralNumber) {
            this.coinPosition.push(newPoint)
          }
        }
      }
      if (this.coinPosition.length !== this.mineralNumber) {
        this.createCircle()
      } else {
        for (const i in this.mineralList) {
          this.mineralList[i].x = this.coinPosition[i].x
          this.mineralList[i].y = this.coinPosition[i].y
        }
      }
      // console.log(this.coinPosition);
    },
    // 轮播图列表
    async getBannerList () {
      const resp = await queryBannerList()
      if (!resp.success) return
      this.bannerList = resp.result
    },
    // banner点击
    bannerClick (item) {
      // 分类 1-视频页
      if (item.type === 1) {
        this.$gbUtils.handleToPage('/videoList')
      }
    },
    // 判断是否登录
    judgeLogin () {
      if (!this.authorized || !this.myAcount) {
        if (this.loginPopShow) return
        this.loginPopShow = true
      }
    },
    // 隐藏弹窗
    hidePop () {
      this.loginPopShow = false
    },
    // 登录成功
    loginSuccess () {
      this.loginPopShow = false
      this.getMineral()
      this.getWalletInfo()
      this.getMyWallet()
    },
    // 跳转骑士卡
    handleToKnight () {
      this.$gbUtils.handleToPage('/knight')
    },
    async getAnnouncementList () {
      const resp = await queryNoticeList({
        pageNumber: 1,
        pageSize: 1
      })
      // uni.stopPullDownRefresh()
      if (!resp.success) return
      const announcement = resp.result.records[0]
      this.announcement = announcement
    }
  },
  computed: {
    ...mapState(['mbLang', 'myAcount', 'authorized']),
    home () {
      return this.$t('home')
    },
    style () {
      return this.$t('home.style')
    },
    boostValue () {
      return this.$t('home.boostValue')
    },
    forceValue () {
      return this.$t('home.forceValue')
    },
    gepNotice () {
      return this.$t('home.gepNotice')
    },
    connect () {
      return this.$t('home.connect')
    },
    change () {
      return this.$t('home.change')
    },
    level () {
      return this.$t('home.level')
    },
    getAll () {
      return this.$t('home.getAll')
    },
    knightLevel () {
      return this.$t('knight.level')
    }
    // halfWidth (px) {
    //   return uni.upx2px(px) + 'px'
    // }
  },
  watch: {
    authorized (val, oldVal) {
      if (!val) {
        this.judgeLogin()
      }
    },
    myAcount (val, oldVal) {
      if (!val) {
        this.judgeLogin()
      }
    }
  },
  mounted () {
    // 隐藏原生tabBar
    // this.hideTabBar()
    this.getMineral()
    this.getWalletInfo()
    // this.getBannerList()
    this.getMyWallet()
    this.judgeLogin()
    // this.getAnnouncementList()
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  min-height: 100%;
  position: relative;
  // padding-bottom: 50px;
  // box-sizing: border-box;
  .header {
    // min-height: 175px;
    // width: 100%;
    background-size: 100% 100%;
    box-sizing: border-box;
    // background-color: #fff;
    // border-radius: 0 0 10px 10px;
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    padding: 0px 5px;
    .wallet-panel {
      box-sizing: border-box;
      // width: 100%;
      min-height: 85px;
      background: #12142d;
      opacity: 1;
      border-radius: 10px;
      font-weight: 400;
      padding: 10px 0;
      color: #fff;
      .connect-wallet-notice {
        font-weight: 100;
        font-size: 12px;
        color: #cbcbcb;
        padding: 0 20px;
        font-weight: lighter;
        // width: 86.5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 7px;
          height: 7px;
          margin-left: 5px;
        }
        span {
          // box-sizing: border-box;
          margin-left: 5px;
          color: #888888;
          cursor: pointer;
          padding: 3px 0;
          width: 70px;
          text-align: center;
          display: inline-block;
          background-color: #282a46;
          border-radius: 12px;
        }
        .avatar-and-hash {
          display: flex;
          // padding-left: 15px;
          align-items: center;
          justify-content: flex-start;
          div {
            margin-left: 3px;
          }
          .avatar {
            width: 17px;
            height: 17px;
            border-radius: 30px;
            margin: 0 7px;
          }
        }
      }
      .wallet-info {
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;
        .avatar-and-hash {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          div {
            margin-left: 3px;
          }
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 30px;
          }
        }
        .rank-and-btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 10px;
          .rank {
            min-width: 40px;
          }
          .change-wallet {
            min-width: 55px;
            height: 25px;
            border: 1px solid #fcfcfc;
            line-height: 25px;
            border-radius: 10px;
            text-align: center;
            font-weight: 200;
            color: #cbcbcb;
            cursor: pointer;
          }
        }
      }
      .nft-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: baseline;
        font-size: 12px;
        .nft-tag {
          height: 25px;
          line-height: 25px;
          background: linear-gradient(90deg, #a37b42 0%, #c2a86a 100%);
          border-radius: 10px;
          padding: 1px 7px;
          font-size: 12px;
          font-weight: bold;
          color: #12142d;
          margin-right: 8px;
          .nft-icon {
            width: auto;
            height: 18px;
            margin-right: 4px;
            vertical-align: -0.3em;
          }
          &.nft-bg-1 {
            background: #8f6d37;
          }
          &.nft-bg-2 {
            background: #997940;
          }
          &.nft-bg-3 {
            background: #a4854a;
          }
          &.nft-bg-4 {
            background: #ae9153;
          }
          &.nft-bg-5 {
            background: #b99d5d;
          }
          &.nft-bg-6 {
            background: #c3a966;
          }
        }
        .line-jump {
          color: #7b8fbb;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .data-group {
      width: 100%;
      margin: 0 auto;
      background-size: 100% 100%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      padding-top: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .progress {
        display: flex;
        align-items: center;
        width: 70%;
        ::v-deep .van-progress {
          width: 100%;
          background-color: transparent;
        }
      }
      .data {
        margin: 0 auto;
        text-align: center;
        color: #666877;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 5px;
        // @keyframes rotate {
        //   from {
        //     transform: rotate(0deg);
        //   }
        //   to {
        //     transform: rotate(360deg);
        //   }
        // }
        .img {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          // padding-top: 5px;
          // padding-top: 3px;
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
          margin-left: 10px;
          // animation: rotate 3s linear infinite;
          border-radius: 50%;
        }
        .data-num {
          color: #b1b1b1;
          font-size: 12px;
          text-indent: 2px;
          padding-top: 0px;
          margin-left: 10px;
        }
        .data-name {
          font-size: 12px;
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }
    .lang {
      width: 25px;
      min-height: 17px;
      background: #d98c10;
      border-radius: 3px;
      font-size: 13px;
      text-align: center;
      margin-right: 25px;
      position: relative;
      z-index: 1;
    }
  }
}
.swiper {
  margin: 0 auto;
  width: 345px;
  height: 100px;
  border-radius: 5px;
  margin-bottom: 10px;
  img {
    margin: 0 auto;
    width: 345px;
    height: 100px;
    border-radius: 5px;
  }
}
.coin-group {
  width: 100%;
  height: 452px;
  background-size: 100% 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  padding-top: 10px;
  position: relative;
  .change-all {
    cursor: pointer;
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    height: 25px;
    font-size: 12px;
    color: #fff;
    // padding: 0 5px;
    // max-width: 85px;
    width: 85px;
    text-align: center;
    border-radius: 13px;
    text-align: center;
    background-color: #16172a;
    img {
      margin: 0 3px;
      margin-left: 4px;
      width: 18px;
      height: 17px;
    }
  }
  @keyframes bounce-down {
    25% {
      transform: translateY(-2px);
    }
    50%,
    100% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(2px);
    }
  }
  @keyframes fade-In {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-Out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .random-panel {
    margin-top: 20px;
    box-sizing: border-box;
    position: relative;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -62%);
    box-sizing: border-box;
    width: 90%;
    height: 300px;
    // background-color: red;
    text-align: center;
    .random-item {
      position: absolute;
      height: 50px;
      width: 50px;
      border-radius: 25px;
      text-align: center;
      color: #f7ecd0;
      font-size: 12px;
      padding-bottom: -2px;
      opacity: 1;
      animation: bounce-down 3s linear infinite;
      transition: all 0.3s ease;
      .random-coin {
        width: 37px;
        height: 37px;
      }
      .random-num {
        display: block;
        margin-top: -3px;
        margin-left: -5px;
        color: #818086;
      }
    }
    .no-data {
      width: 200px;
      height: 200px;
    }
  }
  .button-group {
    box-sizing: border-box;
    padding: 0 20px;
    padding-top: 20px;
    .warehouse-btn {
      height: 50px;
      background: #24c294;
      border-radius: 10px;
      font-size: 17px;
      font-weight: bold;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
    .GEP-notice {
      color: #818086;
      text-align: center;
      font-size: 12px;
      padding-top: 10px;
      font-weight: lighter;
    }
  }
}

.title-name-zh {
  color: red;
}
.title-name-en {
  color: #2b85e4;
}
.test {
  height: 700upx;
  height: 500upx;
  background-color: red;
}
.none {
  padding-top: 90px;
  width: 200px;
  height: 120px;
}
.w75 {
  width: 75px;
}
.point-wrap {
  padding-top: 4px;
  max-width: 80px;
  display: flex;
  margin-right: 15px;
  // align-items: center;
  // justify-content: center;
}
.point-wrapper {
  font-size: 12px;
  .point-icon {
    // padding-top: 5px;
    width: 16px;
    height: 16px;
    padding: 0;
    margin-right: 5px;
  }
}
</style>
