<template>
  <VanPopup
    v-model="popupShow"
    @click-overlay="closePop"
    style="width: 93%; max-width: 500px"
    round
    get-container="content"
  >
    <div class="wrapper">
      <div class="close-wrapper">
        <div class="close-icon" @click="closePop">✕</div>
      </div>
      <div class="title">
        {{ mbLang === 'ZH' ? dataSource.zhTitle : dataSource.englishTitle}}
      </div>
      <div class="time">{{ dataSource.createTime }}</div>
      <div class="content" v-html="mbLang === 'ZH' ? dataSource.zhText : dataSource.englishText"></div>
    </div>
  </VanPopup>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'NoticePopup',
  data () {
    return {
      popupShow: true
    }
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState(['mbLang'])
  },
  methods: {
    closePop () {
      this.$emit('close')
    }
  },
  mounted () {
    // console.log(this.dataSource)
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  min-height: 30vh;
  max-height: 80vh;
  .close-wrapper {
    display: flex;
    justify-content: flex-end;
    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #24c294;
      border-radius: 10px;
      text-align: center;
      line-height: 20px;
      color: #fff;
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2em;
  }
  .time {
    padding-top: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #c1c1c1;
  }
  .content {
    padding-top: 20px;
    overflow-y: scroll;
    max-height: 50vh;
    line-height: 1.5em;
  }
}
</style>
