import request from '@/utils/request'

// 获取轮播图
export const queryBannerList = (params) => {
  return request('GET', '/app/banner/list', params)
}

// 获取矿石
export const queryMineral = (params) => {
  return request('GET', '/app/gold_ore/mineral', params)
}
// 收取矿石
export const queryGetMineral = (params) => {
  return request('POST', '/app/mining/mining_income', params, true, false, 1)
}
// 一键收取矿石
export const queryGetAllMineral = (params) => {
  return request('POST', '/app/mining/oneKey_mining_income', params, true, false, 1)
}
export const sendCoin = (params) => {
  return request('GET', '/app/force_value/oreReleaseTest', params)
}

export const sendForceValue = (params) => {
  return request('GET', '/app/force_value/valueReleaseTest', params)
}

// 绑定上下级  已合并到质押lp 弃用
export const queryBindingWallet = (params) => {
  return request('GET', '/app/wallet/binding', params)
}
