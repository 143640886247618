<template>
  <div class="notice-bar" v-if="announcement">
    <svg class="icon" aria-hidden="true">
      <use xlink:href="#icon-trumpet"></use>
    </svg>
    <!-- @click="handleNoticeClick" -->
    <van-notice-bar
      @click="$gbUtils.handleToPage(`/notice`)"
      style="
        background: transparent;
        width: 90%;
        color: #2a2b33;
        font-weight: bold;
        cursor: pointer;
      "
      :text="announcement.englishTitle"
    />
    <svg
      class="icon list"
      aria-hidden="true"
      @click="$gbUtils.handleToPage(`/notice`)"
    >
      <use xlink:href="#icon-list"></use>
    </svg>
    <NoticePopup
      @close="closePop"
      v-if="popupShow"
      :dataSource="announcement"
    ></NoticePopup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NoticePopup from '../../notice/components/NoticePopup.vue'

export default {
  name: 'HomeNotice',
  components: {
    NoticePopup
  },
  props: {
    announcement: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      popupShow: false
    }
  },
  computed: {
    ...mapState(['mbLang', 'myAcount', 'authorized'])
  },
  methods: {
    // 显示公告
    handleNoticeClick () {
      this.popupShow = true
    },
    // 关闭弹窗
    closePop () {
      this.popupShow = false
    },
    // 公告弹窗是否弹出
    popIsShow () {
      this.dataSource = this.announcement
      const showId = localStorage.getItem('noticeShowId')
      if (!showId) {
        this.popupShow = true
        console.log('popupShow', this.popupShow)
        localStorage.setItem('noticeShowId', this.announcement.id)
      } else if (showId !== this.announcement.id) {
        this.popupShow = true
        localStorage.setItem('noticeShowId', this.announcement.id)
      }
    }

  },
  watch: {
    announcement (val) {
      // this.popIsShow()
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-bar {
  padding: 0 20px;
  display: flex;
  align-items: center;
  .icon {
    width: 16px;
    height: 16px;
  }
  .list {
    cursor: pointer;
  }
}
</style>
